import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import {
    ApiError,
    createFeedbackReport,
    fetchCommentsSince,
    fetchOnlineTicketsCount,
    fetchTicket,
    fetchTicketOfflineNext,
    fetchTicketOnlineNext,
    fetchUserTicketHistory,
    getTicketsToMerge,
    postSubmitByCommentId,
    postTicketCrumb,
    postTicketMerge,
    resetTicket,
    StaleTicketUpdate,
} from '@tymely/api';
import {
    getVisibleArgs,
    IComment,
    IDineshTicketOperations,
    isFalsy,
    isPolicyEvaluated,
    ITicket,
    ITicketTrailCrumb,
    ITicketTrailStatus,
    submittingComments,
    ticketAtom,
    TicketHistoryInfo,
    ticketSessionId,
} from '@tymely/atoms';
import { MULTIPLE_LOGIC_BOX_ID, NEITHER_LOGIC_BOX_ID } from '@tymely/config';

import { useSetAlert } from './alerts.services';
import {
    useHasPolicy,
    useOrganizationsQuery,
    useSelectedChannels,
    useSelectedOrganizations,
} from './organization.services';
import {
    ARGUMENTS_QUERY_KEY,
    DECISION_QUERY_KEY,
    useAgentResponse,
    useDecisionQuery,
    useSelectedComment,
    useSetCommentIdToFocusOn,
} from './comment.services';
import { useArgumentsQuery } from './argument.services';
import { AppMode, useAppMode } from './mode';
import { useCreateHandlingDurationsCrumb } from './ticketTrail.services';
import { useUser } from './auth.services';
import { useFeatureFlags } from './feature.services';

export const MERGE_TICKET_QUERY_KEY = 'ticketsToMerge';
export const TICKET_COUNT_QUERY_KEY = 'ticketCount';
export const OFFLINE_TICKET_QUERY_KEY = 'offline-ticket';

export enum IWaitType {
    POLICY_EVALUATION = 'POLICY_EVALUATION',
    TAGGING = 'TAGGING',
    UNTAGGING = 'UNTAGGING',
    USER_WAITED_FOR_NEW_TICKET = 'USER_WAITED_FOR_NEW_TICKET',
}

export const useTicket = () => useRecoilValue(ticketAtom);
export const useUnsetTicket = () => {
    const unset = useResetRecoilState(ticketAtom);
    const { flushTimers } = useTicketUserWaited();
    return () => {
        flushTimers();
        return unset();
    };
};

export const useTicketSessionId = () => useRecoilValue(ticketSessionId);
export const useSetTicketSessionId = () => {
    const setTicketSessionId = useSetRecoilState(ticketSessionId);
    return useCallback(() => setTicketSessionId(crypto.randomUUID()), [setTicketSessionId]);
};

export const sortCommentsByInquiryDate = (comments: IComment[]) =>
    comments
        .slice()
        .sort(
            (comment1, comment2) =>
                new Date(comment1.inquiry_date).valueOf() - new Date(comment2.inquiry_date).valueOf(),
        );

export const useSetTicket = () => {
    const currentTicket = useTicket();
    const setTicket = useSetRecoilState(ticketAtom);
    const setTicketCommentIdToFocusOn = useSetCommentIdToFocusOn();
    const { flushTimers } = useTicketUserWaited();

    return useCallback(
        (ticket: ITicket) => {
            if (currentTicket?.id) {
                flushTimers();
            }
            return setTicket({
                ...ticket,
                comments: sortCommentsByInquiryDate(ticket.comments || []),
            });
        },
        [currentTicket, setTicket, setTicketCommentIdToFocusOn],
    );
};

export const useTicketOfflineQuery = (
    ticketId?: number,
    options?: UseQueryOptions<ITicket | null, unknown, ITicket | null, (string | number | null)[]> & {
        chatMode?: boolean;
    },
) => {
    const { chatMode, ...rest } = options ?? {};

    const currentTicket = useTicket();
    const selectedChannels = useSelectedChannels();
    const selectedOrganizations = useSelectedOrganizations();
    const client = useQueryClient();
    const setTicket = useSetTicket();
    const { appMode } = useAppMode();

    const fetchTicketOffline = async (ticketId?: number) => {
        return ticketId
            ? await fetchTicket(ticketId)
            : await fetchTicketOfflineNext(
                  selectedOrganizations.map((org) => org.id),
                  currentTicket?.id,
                  appMode,
              );
    };

    return useQuery(
        [OFFLINE_TICKET_QUERY_KEY, ticketId || null],
        () =>
            chatMode && !ticketId
                ? fetchTicketOnlineNext(
                      selectedChannels
                          .filter((ch) => ch.organization.config.chat_enabled)
                          .map((ch) => ({
                              organization_id: ch.organization.id,
                              channel: ch.name,
                          })),
                  )
                : fetchTicketOffline(ticketId),
        {
            staleTime: Infinity,
            ...rest,
            onSettled(ticket, error) {
                if (ticket && !ticketId) {
                    client.setQueryData([OFFLINE_TICKET_QUERY_KEY, ticket.id], ticket);
                }
                if (ticket) {
                    setTicket(ticket);
                }
                options?.onSettled?.(ticket, error);
            },
        },
    );
};

export const useOnlineTicketQuery = (options?: UseQueryOptions<ITicket | null, unknown, ITicket | null, string[]>) => {
    const { isChat } = useAppMode();
    const setTicket = useSetTicket();
    const unsetTicket = useUnsetTicket();
    const selectedChannels = useSelectedChannels();

    const timer = useTicketUserWaitedForNewTicket();

    return useQuery(
        ['online-ticket'],
        async () => {
            const ticket: ITicket | null = await fetchTicketOnlineNext(
                selectedChannels
                    .filter((ch) => !isChat || ch.organization.config.chat_enabled)
                    .map((ch) => ({
                        organization_id: ch.organization.id,
                        channel: ch.name,
                    })),
            );
            return ticket;
        },
        {
            staleTime: Infinity,
            enabled: true,
            ...options,
            onSettled: (ticket, error) => {
                if (!ticket) {
                    unsetTicket();
                    timer.start(IWaitType.USER_WAITED_FOR_NEW_TICKET);
                }
                if (ticket) {
                    setTicket(ticket);
                    timer.stop(IWaitType.USER_WAITED_FOR_NEW_TICKET, ticket);
                    timer.flushTimers();
                }
                options?.onSettled?.(ticket, error);
            },
        },
    );
};

export const useSetTicketProps = () => {
    const setTicket = useSetRecoilState(ticketAtom);

    return useCallback(
        (props: Partial<ITicket>) => {
            setTicket((ticket) => ticket && { ...ticket, ...props });
        },
        [setTicket],
    );
};

export const useTicketFinalizeSubmit = () => {
    const selectedComment = useSelectedComment();
    const setAlert = useSetAlert();
    const { data: agentResponse } = useAgentResponse();
    const { appMode = AppMode.Training } = useAppMode();
    const { data: decision } = useDecisionQuery(false);
    const [, setSubmitting] = useRecoilState<{ [key: IComment['id']]: boolean }>(submittingComments);
    const createHandlingDurationsCrumb = useCreateHandlingDurationsCrumb();

    return useCallback(
        async (dryMode = false, afterHandling = true) => {
            try {
                if (!selectedComment) {
                    return Promise.reject('Comment is not selected');
                }

                createHandlingDurationsCrumb(IDineshTicketOperations.USER_SUBMITTED_TICKET);

                setSubmitting((submitting) => ({
                    ...submitting,
                    [selectedComment.id]: true,
                }));

                const postPromise = postSubmitByCommentId(
                    selectedComment.id,
                    dryMode,
                    appMode,
                    agentResponse,
                    afterHandling,
                );

                if (decision?.status === 'BETA') {
                    return await postPromise;
                } else {
                    return postPromise
                        .catch(async (e) => {
                            if (e instanceof StaleTicketUpdate) {
                                return;
                            }
                            throw e;
                        })
                        .finally(() => setSubmitting((map) => omit(map, selectedComment.id)));
                }
            } catch (error) {
                setAlert(
                    error instanceof StaleTicketUpdate
                        ? error.message
                        : 'Ticket submission has failed due to an external update',
                    'error',
                );
                return Promise.reject('Ticket submission has failed');
            }
        },
        [selectedComment, agentResponse, setAlert],
    );
};

export const useTicketHistory = () => {
    const currentTicket = useTicket();
    const queryClient = useQueryClient();
    const selectedComment = useSelectedComment();
    const { appMode } = useAppMode();

    const { mutateAsync: mergeMutation } = useMutation(
        (params: {
            ticketId: ITicket['id'];
            commentId: IComment['id'];
            appMode: AppMode;
            mergeTicketIds: ITicket['id'][];
        }) => postTicketMerge(params.ticketId, params.mergeTicketIds, params.commentId, params.appMode),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([DECISION_QUERY_KEY, selectedComment?.id]);
                queryClient.invalidateQueries([ARGUMENTS_QUERY_KEY, selectedComment?.id]);
            },
        },
    );

    const ticketsToMergeQuery = useQuery(
        [MERGE_TICKET_QUERY_KEY, currentTicket?.id],
        () => {
            return getTicketsToMerge(currentTicket.id);
        },
        { enabled: Boolean(currentTicket) },
    );

    const isTicketMarkedForMerge = useCallback(
        (ticket: ITicket) => ticketsToMergeQuery.data?.includes(ticket.id) || false,
        [ticketsToMergeQuery.data],
    );

    const toggleTicketMerge = useCallback(
        async (ticket: ITicket) => {
            if (!selectedComment || !appMode) {
                return;
            }
            const mergeTicketIds = ticketsToMergeQuery.data || [];
            const index = mergeTicketIds.findIndex((t) => t === ticket.id);
            if (index === -1) {
                mergeTicketIds.push(ticket.id);
            } else {
                mergeTicketIds.splice(index, 1);
            }

            return mergeMutation({
                ticketId: currentTicket.id,
                commentId: selectedComment?.id,
                appMode,
                mergeTicketIds,
            }).then(() => {
                queryClient.setQueryData(['ticketsToMerge', currentTicket.id], mergeTicketIds);
            });
        },
        [currentTicket, selectedComment, appMode, queryClient, mergeMutation, ticketsToMergeQuery.data],
    );

    useFeatureFlags();

    const historyQuery = useQuery<TicketHistoryInfo, AxiosError>(
        ['ticketHistory', currentTicket?.id],
        () => {
            if (!currentTicket) {
                return Promise.reject('Ticket is not loaded');
            }
            return fetchUserTicketHistory(
                currentTicket?.id,
                currentTicket.organization_id,
                currentTicket.origin_customer_id,
                true,
            ).then((response) => ({
                ...response,
                tickets: response.tickets
                    ? response.tickets.sort(
                          (t1, t2) => new Date(t1.inquiry_date).valueOf() - new Date(t2.inquiry_date).valueOf(),
                      )
                    : [],
            }));
        },
        {
            enabled: Boolean(currentTicket) && !currentTicket.organization.disabled,
        },
    );
    return {
        ...historyQuery,
        history: historyQuery.data,
        ticketsToMerge: ticketsToMergeQuery.data,
        toggleTicketMerge,
        isTicketMarkedForMerge,
    };
};

export const useTicketCountQuery = () => {
    const selectedChannels = useSelectedChannels();
    const channelDefs = selectedChannels.map((ch) => ({ organization_id: ch.organization.id, channel: ch.name }));

    return useQuery(TICKET_COUNT_QUERY_KEY, () => fetchOnlineTicketsCount(channelDefs), {
        enabled: selectedChannels.length > 0,
    });
};

export const useIsSecondTouchTicket = (ticket: ITicket) => {
    return useMemo(() => {
        const comments = sortCommentsByInquiryDate(ticket?.comments || []);
        const customerInquiry = comments.find(({ is_customer }) => {
            return is_customer;
        });
        const agentResponse = comments.find(
            ({ is_customer, is_public, inquiry_date }) =>
                customerInquiry && inquiry_date > customerInquiry.inquiry_date && !is_customer && is_public !== false,
        );
        if (!agentResponse) return false;

        return comments.some(({ is_customer, inquiry_date }) => {
            return inquiry_date > agentResponse.inquiry_date && is_customer;
        });
    }, [ticket]);
};

type UseUpdateCommentsQueryOptions = UseQueryOptions<IComment[], unknown, IComment[], (string | number)[]>;

export const useUpdateCommentsQuery = (options?: UseUpdateCommentsQueryOptions) => {
    const currentTicket = useTicket();
    const setTicket = useSetTicketProps();
    const setComment = useSetCommentIdToFocusOn();

    return useQuery(
        ['pollComments', currentTicket?.id],
        () => {
            if (!currentTicket?.id) return Promise.reject('Ticket is not loaded');
            const lastComment = (currentTicket?.comments || []).at(-1);
            return fetchCommentsSince(
                currentTicket.id,
                lastComment?.created_date,
                lastComment?.updated_at || lastComment?.created_date,
            );
        },
        {
            refetchOnMount: false,
            enabled: false,
            retry: false,
            initialData: currentTicket?.comments || [],
            ...options,
            onSuccess: (comments) => {
                const oldComments = currentTicket?.comments || [];
                const newComments = sortCommentsByInquiryDate(comments).filter(
                    ({ id }) => !oldComments.find((c) => c.id === id),
                );
                if (newComments.length) {
                    const commentId = newComments[newComments.length - 1].id;
                    setComment(commentId);
                    setTicket({ comments: oldComments.concat(newComments) });
                }
                options?.onSuccess?.(comments);
            },
        },
    );
};

export const useTicketReset = () => {
    const ticket = useTicket();
    const setTicket = useSetTicket();
    const queryClient = useQueryClient();
    const { updateDecision } = useDecisionQuery(false);

    return useCallback(async () => {
        if (!ticket) {
            return Promise.reject('Ticket is not set');
        }

        const updatedTicket = await resetTicket(ticket.id);

        queryClient.invalidateQueries([MERGE_TICKET_QUERY_KEY]);
        setTicket(updatedTicket);
        updateDecision(null);

        return updatedTicket;
    }, [ticket, setTicket, updateDecision]);
};

const ACTIONABLE_STATUSES = ['LIVE', 'BETA', 'EXTERNAL_TANDEM'];

export const useTicketActions = () => {
    const comment = useSelectedComment();
    const ticket = useTicket();
    const policyEvaluated = useRecoilValue(isPolicyEvaluated);
    const { appMode, isOnline } = useAppMode();
    const { data: hasPolicy = true } = useHasPolicy(
        ticket.organization.org_policy_set_id,
        comment?.selected_intent_id || null,
    );
    const { data: decision } = useDecisionQuery(policyEvaluated);
    const { data: args } = useArgumentsQuery({
        commentId: comment?.id,
        enabled: false,
    });
    const { isChat } = useAppMode();
    const decisionHasActions = !!decision?.actions?.length;
    const decisionIsActionable = ACTIONABLE_STATUSES.includes(decision?.status ?? '');
    const ticketIsLive = ticket?.live ?? isOnline;
    const commentAlreadySubmitted = !!comment?.submitted_at;
    const textArguments = useMemo(
        () => args?.filter(({ arg_type }) => ['TEXT_ARGUMENT', 'INFO_ARGUMENT', 'USER_INPUT'].includes(arg_type)),
        [args],
    );

    if (appMode === AppMode.Admin) {
        const cannotSubmitExplanation = !decisionHasActions
            ? 'Decision has no actions'
            : commentAlreadySubmitted && !isChat
              ? `Already submitted at ${
                    comment?.submitted_at ? new Date(comment.submitted_at).toLocaleString() : 'unknown'
                }`
              : undefined;
        return {
            reviewEnabled: true,
            nextEnabled: true,
            saveEnabled: decisionHasActions,
            submitEnabled: !cannotSubmitExplanation,
            cannotSubmitExplanation,
        };
    } else if (appMode !== AppMode.Online) {
        return {
            reviewEnabled: true,
            nextEnabled: true,
            saveEnabled: false,
            submitEnabled: false,
            cannotSubmitExplanation: 'Ticket can only be submitted in Online mode',
        };
    }

    const textArgsFilled = textArguments?.length
        ? getVisibleArgs(textArguments).every((arg) => !isFalsy(arg)) && policyEvaluated
        : true;

    const latestCustomerCommentInquiryDate = ticket?.comments
        .filter((c) => c.is_customer)
        .sort((a, b) => b.id - a.id)[0]?.inquiry_date;

    const cannotSubmitExplanation = !textArgsFilled
        ? 'All text arguments must be filled'
        : !decisionHasActions
          ? 'Decision has no actions'
          : !decisionIsActionable
            ? `Decision is ${decision?.status}`
            : decision?.execution_status !== 'PENDING'
              ? `Decision is ${decision?.execution_status}`
              : !ticketIsLive
                ? 'Ticket can only be submitted in Online mode'
                : commentAlreadySubmitted && !isChat
                  ? `Already submitted at ${comment?.submitted_at ? new Date(comment.submitted_at).toLocaleString() : 'unknown'}`
                  : comment?.inquiry_date !== latestCustomerCommentInquiryDate
                    ? 'Not the latest comment'
                    : undefined;

    const ambiguousIntent =
        comment?.selected_intent_id === MULTIPLE_LOGIC_BOX_ID || comment?.selected_intent_id === NEITHER_LOGIC_BOX_ID;
    const reviewEnabled = textArgsFilled || ambiguousIntent;

    const nextEnabled = !hasPolicy || decision?.status === 'SKIP' || !decisionHasActions;
    const saveEnabled = textArgsFilled && decisionHasActions && decision?.status === 'TANDEM';

    return {
        reviewEnabled,
        nextEnabled,
        saveEnabled,
        submitEnabled: !cannotSubmitExplanation,
        cannotSubmitExplanation,
    };
};

export const useTicketOrganization = () => {
    const ticket = useTicket();
    const { data: orgs, isLoading: isLoadingOrgs } = useOrganizationsQuery();
    return useMemo(
        () => orgs?.find((org) => org.id === ticket?.organization_id) || ticket?.organization,
        [ticket?.organization_id, isLoadingOrgs],
    );
};

export const useFeedbackReport = () => {
    const user = useUser();
    const setAlert = useSetAlert();

    return useMutation('FEEDBACK_REPORT', () => createFeedbackReport(user?.email ? [user.email] : []), {
        onSuccess: async () => {
            setAlert("Feedback report is being created. You'll receive an email when it's ready.");
        },
        onError: (error: ApiError) => {
            setAlert(`Failed to create tandem report. ${error.message}`, 'error');
        },
    });
};

//

type TimerHistoryRecord = {
    type: IWaitType;
    waitedFor: number;
    start: Date;
    stop: Date;
};

let timersHistory: TimerHistoryRecord[] = [];
let waitTimes: Partial<Record<IWaitType, number>> = {};
let startTimes: Partial<Record<IWaitType, Date>> = {};
let waitTicketTrail: ITicketTrailCrumb;

const useTicketTimes = (operation: IDineshTicketOperations) => {
    const user = useUser();
    const currentTicket = useTicket();
    const selectedComment = useSelectedComment();
    const { data: decision } = useDecisionQuery(false);

    const start = React.useCallback((type: IWaitType) => {
        if (!startTimes[type]) {
            startTimes[type] = new Date();
        }
    }, []);

    const stop = React.useCallback(
        (type: IWaitType, ticket?: ITicket) => {
            const _ticket = ticket ?? currentTicket;
            const start = startTimes[type];
            if (!start || !_ticket) {
                return;
            }
            waitTicketTrail = {
                operation,
                ticket_id: _ticket.id,
                status: ITicketTrailStatus.SUCCESS,
                origin_ticket_id: _ticket.original_id_from_client,
                organization_id: _ticket.organization_id,
                additional_data: {
                    workflow_id: decision?.workflow_id,
                    intent_id: selectedComment?.selected_intent_id,
                },
            };

            const now = new Date();
            const waitedFor = Math.round(now.getTime() - start.getTime());
            timersHistory.push({ type, waitedFor, start, stop: now });
            waitTimes[type] = (waitTimes[type] || 0) + waitedFor;
            startTimes = omit(startTimes, type);

            return waitedFor;
        },
        [currentTicket],
    );

    const waitFor = (type: IWaitType, promise: Promise<unknown>) => {
        start(type);
        return promise.finally(() => stop(type));
    };

    const flushTimers = () => {
        if (!isEmpty(waitTimes)) {
            postTicketCrumb({
                ...waitTicketTrail,
                additional_data: {
                    ...waitTicketTrail.additional_data,
                    ...waitTimes,
                    total: Object.entries(waitTimes).reduce((total, [_, time]) => total + time, 0),
                    timers: timersHistory,
                    username: user?.username,
                },
            });

            waitTimes = {};
            startTimes = {};
        }
        timersHistory = [];
    };

    return {
        start,
        stop,
        waitFor,
        flushTimers,
    };
};

const useTicketUserWaitedForNewTicket = () => useTicketTimes(IDineshTicketOperations.USER_WAITED_FOR_NEW_TICKET);

export const useTicketUserWaited = () => useTicketTimes(IDineshTicketOperations.USER_WAITED);

export const useWaitWhileLoading = (type: IWaitType, isLoading: boolean) => {
    const timer = useTicketUserWaited();

    useEffect(() => {
        if (isLoading) {
            timer.start(type);
        } else {
            timer.stop(type);
        }
    }, [isLoading]);
};
